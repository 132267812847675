<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="row ml-0 mt-3 mb-3 mr-0">
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date1">
                    </div>
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date2">
                    </div>
                    <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                        <button class="btn btn-warning btn-block" @click="search"><i class="fas fa-search mr-2"></i>Buscar</button>
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="mt-3 mb-3">
                    <div class="card" v-if="Object.keys(cash).length > 0">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-8 mt-1">Caixa geral</div>
                                <div class="col-4">
                                    <button class="btn btn-block btn-sm btn-primary" @click="print">
                                        <template v-if="platform == 'desktop'">
                                            <i class="fas fa-print mr-2"></i>Imprimir
                                        </template>
                                        <template v-else>
                                            <i class="fas fa-print"></i>
                                        </template>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-12 p-0 text-truncate">
                                    <div class="pl-3 pr-3 pt-2 pb-2 bg-secondary text-light rounded">Relatório normal</div>
                                </div>
                            </div><!-- /row -->
                            <div class="chart mt-3 mb-3" v-show="platform == 'desktop' && cash.total_apuracao">
                                <canvas id="graphic1" width="400" height="100"></canvas>
                            </div>
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Apurou</div>
                                <div class="col text-truncate">{{cash.total_apuracao - cash.total_apuracao_ao_vivo - cash.total_apuracao_acumuladao | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Qtd. Apostas</div>
                                <div class="col text-truncate">{{cash.num_apostas - cash.num_apostas_ao_vivo - cash.num_apostas_acumuladao | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Qtd. Ganhadores</div>
                                <div class="col text-truncate">{{cash.num_premios - cash.num_premios_ao_vivo - cash.num_premios_acumuladao | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Qtd. Apostas abertas</div>
                                <div class="col text-truncate">{{cash.num_apostas_abertas - cash.num_apostas_abertas_ao_vivo | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Entradas em aberto</div>
                                <div class="col text-truncate">{{cash.total_apostas_abertas - cash.total_apostas_abertas_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Saldo em prêmios</div>
                                <div class="col text-truncate">{{cash.total_premios - cash.total_premios_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões' : 'S. Comissões'}}</div>
                                <div class="col text-truncate">{{cash.total_comissao | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2">
                                <div class="col text-truncate">Saldo final</div>
                                <div class="col text-truncate" :class="positiveOrNegative((cash.total_apuracao - cash.total_premios - cash.total_apuracao_ao_vivo - cash.total_apuracao_acumuladao - cash.total_comissao) + cash.total_premios_ao_vivo + cash.total_premios_acumuladao)">{{(cash.total_apuracao - cash.total_premios - cash.total_apuracao_ao_vivo - cash.total_apuracao_acumuladao - cash.total_comissao) + cash.total_premios_ao_vivo + cash.total_premios_acumuladao | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row mt-2 mb-2">
                                <div class="col-12 p-0 text-truncate">
                                    <div class="pl-3 pr-3 pt-2 pb-2 bg-secondary text-light rounded">Relatório ao vivo</div>
                                </div>
                            </div><!-- /row -->
                            <div class="chart mt-3 mb-3" v-show="platform == 'desktop' && cash.total_apuracao_ao_vivo">
                                <canvas id="graphic2" width="400" height="100"></canvas>
                            </div>
                            <div class="row pt-2 pb-2 bg-f1f1f1">
                                <div class="col text-truncate">Apurou</div>
                                <div class="col text-truncate">{{cash.total_apuracao_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Qtd. Apostas</div>
                                <div class="col text-truncate">{{cash.num_apostas_ao_vivo | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1">
                                <div class="col text-truncate">Qtd. Ganhadores</div>
                                <div class="col text-truncate">{{cash.num_premios_ao_vivo | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Qtd. Apostas abertas</div>
                                <div class="col text-truncate">{{cash.num_apostas_abertas_ao_vivo | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1">
                                <div class="col text-truncate">Entradas em aberto</div>
                                <div class="col text-truncate">{{cash.total_apostas_abertas_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Saldo em prêmios</div>
                                <div class="col text-truncate">{{cash.total_premios_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões' : 'S. Comissões'}}</div>
                                <div class="col text-truncate">{{cash.total_comissao_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2">
                                <div class="col text-truncate">Saldo final</div>
                                <div class="col text-truncate" :class="positiveOrNegative((cash.total_apuracao_ao_vivo - cash.total_premios_ao_vivo - cash.total_comissao_ao_vivo))">{{cash.total_apuracao_ao_vivo - cash.total_premios_ao_vivo - cash.total_comissao_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row mt-2 mb-2" v-show="cash.total_apuracao_acumuladao">
                                <div class="col-12 p-0 text-truncate">
                                    <div class="pl-3 pr-3 pt-2 pb-2 bg-secondary text-light rounded">Relatório acumuladão</div>
                                </div>
                            </div><!-- /row -->
                            <div class="chart mt-3 mb-3" v-show="platform == 'desktop' && cash.total_apuracao_acumuladao">
                                <canvas id="graphic3" width="400" height="100"></canvas>
                            </div>
                            <template v-if="cash.total_apuracao_acumuladao">
                                <div class="row border-bottom pb-2">
                                    <div class="col text-truncate">Apurou</div>
                                    <div class="col text-truncate">{{cash.total_apuracao_acumuladao | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row border-bottom pt-2 pb-2">
                                    <div class="col text-truncate">Qtd. Apostas</div>
                                    <div class="col text-truncate">{{cash.num_apostas_acumuladao | formatValue}}</div>
                                </div><!-- /row -->
                                <div class="row border-bottom pt-2 pb-2">
                                    <div class="col text-truncate">Qtd. Ganhadores</div>
                                    <div class="col text-truncate">{{cash.num_premios_acumuladao | formatValue}}</div>
                                </div><!-- /row -->
                                <div class="row border-bottom pt-2 pb-2">
                                    <div class="col text-truncate">Saldo em prêmios</div>
                                    <div class="col text-truncate">{{cash.total_premios_acumuladao | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row border-bottom pt-2 pb-2">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões' : 'S. Comissões'}}</div>
                                    <div class="col text-truncate">{{cash.total_comissao_acumuladao | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2">
                                    <div class="col text-truncate">Saldo final</div>
                                    <div class="col text-truncate" :class="positiveOrNegative((cash.total_apuracao_acumuladao - cash.total_premios_acumuladao - cash.total_comissao_acumuladao))">{{cash.total_apuracao_acumuladao - cash.total_premios_acumuladao - cash.total_comissao_acumuladao | formatCoin}}</div>
                                </div><!-- /row -->
                            </template>
                            <div class="row mt-2 mb-2">
                                <div class="col-12 p-0 text-truncate">
                                    <div class="pl-3 pr-3 pt-2 pb-2 bg-secondary text-light rounded">Relatório completo</div>
                                </div>
                            </div><!-- /row -->
                            <div class="chart mt-3 mb-3" v-show="platform == 'desktop' && cash.total_apuracao">
                                <canvas id="graphic4" width="400" height="100"></canvas>
                            </div>
                            <div class="row pb-2">
                                <div class="col text-truncate">Apurou</div>
                                <div class="col text-truncate">{{cash.total_apuracao | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Qtd. Apostas</div>
                                <div class="col text-truncate">{{cash.num_apostas | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Qtd. Ganhadores</div>
                                <div class="col text-truncate">{{cash.num_premios | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Qtd. Apostas abertas</div>
                                <div class="col text-truncate">{{cash.num_apostas_abertas - cash.num_apostas_abertas_ao_vivo | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Entradas em aberto</div>
                                <div class="col text-truncate">{{cash.total_apostas_abertas - cash.total_apostas_abertas_ao_vivo | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em lançamentos' : 'S. Lançamentos'}}</div>
                                <div class="col text-truncate">{{cash.total_lancamentos | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em retiradas' : 'S. Retiradas'}}</div>
                                <div class="col text-truncate">{{cash.total_retiradas | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Saldo em prêmios</div>
                                <div class="col text-truncate">{{cash.total_premios | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões' : 'S. Comissões'}}</div>
                                <div class="col text-truncate">{{cash.total_comissao + cash.total_comissao_ao_vivo + cash.total_comissao_acumuladao | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded" v-if="pipe == 2">
                                <div class="col text-truncate">Minha comissão<span class="float-right text-danger">{{comissao}}%</span></div>
                                <div class="col text-truncate">{{calcCommission(cash.total_apuracao, cash.total_premios, cash.total_comissao, cash.total_comissao_ao_vivo, comissao, comissao_do_apurado) | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2" :class="{'pb-2 bg-f1f1f1 rounded': pipe == 3}">
                                <div class="col text-truncate">Saldo final</div>
                                <div class="col text-truncate" :class="positiveOrNegative((cash.total_apuracao - cash.total_premios - cash.total_comissao - cash.total_comissao_acumuladao - cash.total_comissao_ao_vivo))">{{cash.total_apuracao - cash.total_premios - cash.total_comissao - cash.total_comissao_acumuladao - cash.total_comissao_ao_vivo - calcCommission(cash.total_apuracao, cash.total_premios, cash.total_comissao, cash.total_comissao_ao_vivo, comissao, comissao_do_apurado) | formatCoin}}</div>
                            </div><!-- /row -->
                        </div><!-- /card-body -->
                    </div><!-- /card -->
                </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import Chart from 'chart.js/dist/Chart.min.js'
import 'vue-loading-overlay/dist/vue-loading.css'

const today = moment().tz('America/Recife').format('YYYY-MM-DD');

export default {
    data() {
        return {
            pipe: 0,
            cash: {},
            date1: today,
            date2: today,
            isLoading: false,
            comissao: 0,
            comissao_do_apurado: 'nao',
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            }
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        search() {

            const self = this;

            if (self.date1 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data inicial!',
                    'warning'
                );
            } else if (self.date2 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data final!',
                    'warning'
                );
            } else {
                
                self.cash = {};
                self.isLoading = true;

                api.get(`dashboard/cash/general/${self.date1}/between/${self.date2}`).then((response) => {
                    switch (response.data.result) {
                        case 'success':
                           self.cash = response.data.cash;
                           self.comissao = response.data.cash.comissao;
                           self.comissao_do_apurado = response.data.cash.comissao_do_apurado;
                           self.graphic();
                        break;
                        case 'nothing_found':
                            Swal.fire(
                                'Atenção!',
                                'Não encontramos nenhum relatório financeiro!',
                                'warning'
                            )
                        break;
                        default:
                            Swal.fire(
                                'Atenção!',
                                response.data.message,
                                'warning'
                            )
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        graphic() {
            
            setTimeout(() => {

                // Relatório normal
                new Chart(document.getElementById('graphic1').getContext('2d'), {
                    type: 'bar',
                    data: {
                        labels: ['Apuração', 'Premiação', 'Comissão', 'Saldo'],
                        datasets: [{
                            label: 'Total',
                            data: [(this.cash.total_apuracao - this.cash.total_apuracao_ao_vivo - this.cash.total_apuracao_acumuladao), (this.cash.total_premios - this.cash.total_premios_ao_vivo - this.cash.total_premios_acumuladao), this.cash.total_comissao, (this.cash.total_apuracao - this.cash.total_premios - this.cash.total_apuracao_ao_vivo - this.cash.total_apuracao_acumuladao - this.cash.total_comissao) + this.cash.total_premios_ao_vivo + this.cash.total_premios_acumuladao],
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)'
                            ],
                            borderColor: [
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 159, 64, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                });

                // Relatório ao vivo
                new Chart(document.getElementById('graphic2').getContext('2d'), {
                    type: 'bar',
                    data: {
                        labels: ['Apuração', 'Premiação', 'Comissão', 'Saldo'],
                        datasets: [{
                            label: 'Total',
                            data: [this.cash.total_apuracao_ao_vivo, this.cash.total_premios_ao_vivo, this.cash.total_comissao_ao_vivo, (this.cash.total_apuracao_ao_vivo - this.cash.total_premios_ao_vivo - this.cash.total_comissao_ao_vivo)],
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)'
                            ],
                            borderColor: [
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 159, 64, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                });

                // Relatório acumuladão
                new Chart(document.getElementById('graphic3').getContext('2d'), {
                    type: 'bar',
                    data: {
                        labels: ['Apuração', 'Premiação', 'Comissão', 'Saldo'],
                        datasets: [{
                            label: 'Total',
                            data: [this.cash.total_apuracao_acumuladao, this.cash.total_premios_acumuladao, this.cash.total_comissao_acumuladao, (this.cash.total_apuracao_acumuladao - this.cash.total_premios_acumuladao - this.cash.total_comissao_acumuladao)],
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)'
                            ],
                            borderColor: [
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 159, 64, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                });

                // Relatório completo
                new Chart(document.getElementById('graphic4').getContext('2d'), {
                    type: 'bar',
                    data: {
                        labels: ['Apuração', 'Premiação', 'Comissão', 'Saldo'],
                        datasets: [{
                            label: 'Total',
                            data: [this.cash.total_apuracao, this.cash.total_premios, (this.cash.total_comissao + this.cash.total_comissao_ao_vivo + this.cash.total_comissao_acumuladao), (this.cash.total_apuracao - this.cash.total_premios - this.cash.total_comissao - this.cash.total_comissao_acumuladao - this.cash.total_comissao_ao_vivo)],
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)'
                            ],
                            borderColor: [
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 159, 64, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                });
            }, 100);
        },
        print() {

            let date1 = moment(this.date1).format('DD/MM/YYYY');
            let date2 = moment(this.date2).format('DD/MM/YYYY');

            let html = `
                <html>
                    <head>
                        <style>body{font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"}.table{width:100%;border-collapse:collapse}.table td {padding:10px}</style>
                    </head>
                <body>
                    <h1>
                        <center>Relatório normal</center>
                    </h1>
                    <p>
                        <center>Do dia ${date1} até ${date2}</center>
                    </p>
                    <table class="table" border="1">
                        <thead class="thead-dark">
                            <tr>    
                                <th scope="col">Apurou</th>
                                <th scope="col">Apostas</th>
                                <th scope="col">Ganhadores</th>
                                <th scope="col">Prêmios</th>
                                <th scope="col">Comissões</th>
                                <th scope="col">Líquido</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_apuracao - this.cash.total_apuracao_ao_vivo - this.cash.total_apuracao_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatValue(this.cash.num_apostas - this.cash.num_apostas_ao_vivo - this.cash.num_apostas_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatValue(this.cash.num_premios - this.cash.num_premios_ao_vivo - this.cash.num_premios_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_premios - this.cash.total_premios_ao_vivo - this.cash.total_premios_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_comissao)}</td>
                                <td align="center" width="16.66%">${this.formatCoin((this.cash.total_apuracao - this.cash.total_premios - this.cash.total_apuracao_ao_vivo - this.cash.total_apuracao_acumuladao - this.cash.total_comissao) + this.cash.total_premios_ao_vivo + this.cash.total_premios_acumuladao)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h1>
                        <center>Relatório ao vivo</center>
                    </h1>
                    <table class="table" border="1">
                        <thead class="thead-dark">
                            <tr>    
                                <th scope="col">Apurou</th>
                                <th scope="col">Apostas</th>
                                <th scope="col">Ganhadores</th>
                                <th scope="col">Prêmios</th>
                                <th scope="col">Comissões</th>
                                <th scope="col">Líquido</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_apuracao_ao_vivo)}</td>
                                <td align="center" width="16.66%">${this.formatValue(this.cash.num_apostas_ao_vivo)}</td>
                                <td align="center" width="16.66%">${this.formatValue(this.cash.num_premios_ao_vivo)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_premios_ao_vivo)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_comissao_ao_vivo)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_apuracao_ao_vivo - this.cash.total_premios_ao_vivo - this.cash.total_comissao_ao_vivo)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h1>
                        <center>Relatório acumuladão</center>
                    </h1>
                    <table class="table" border="1">
                        <thead class="thead-dark">
                            <tr>    
                                <th scope="col">Apurou</th>
                                <th scope="col">Apostas</th>
                                <th scope="col">Ganhadores</th>
                                <th scope="col">Prêmios</th>
                                <th scope="col">Comissões</th>
                                <th scope="col">Líquido</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_apuracao_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatValue(this.cash.num_apostas_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatValue(this.cash.num_premios_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_premios_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_comissao_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_apuracao_acumuladao - this.cash.total_premios_acumuladao - this.cash.total_comissao_acumuladao)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h1>
                        <center>Relatório completo</center>
                    </h1>
                    <table class="table" border="1">
                        <thead class="thead-dark">
                            <tr>    
                                <th scope="col">Apurou</th>
                                <th scope="col">Apostas</th>
                                <th scope="col">Ganhadores</th>
                                <th scope="col">Prêmios</th>
                                <th scope="col">Comissões</th>
                                <th scope="col">Líquido</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_apuracao)}</td>
                                <td align="center" width="16.66%">${this.formatValue(this.cash.num_apostas)}</td>
                                <td align="center" width="16.66%">${this.formatValue(this.cash.num_premios)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_premios)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_comissao + this.cash.total_comissao_ao_vivo + this.cash.total_comissao_acumuladao)}</td>
                                <td align="center" width="16.66%">${this.formatCoin(this.cash.total_apuracao - this.cash.total_premios - this.cash.total_comissao - this.cash.total_comissao_acumuladao - this.cash.total_comissao_ao_vivo)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h5>
                        <center>${document.location.origin}</center>
                    </h5>
                  </body>
                </html>
            `;

            let print_iframe = document.createElement('iframe');
                
            print_iframe.width = '0px';
            print_iframe.height = '0px';
            print_iframe.src = 'about:blank';
            print_iframe.style.visibility = 'hidden';

            print_iframe.onload = function() {
                let print_script_tag = print_iframe.contentWindow.document.createElement("script");
                    print_script_tag.type = "text/javascript";
       
                let print_script = print_iframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
                    print_script_tag.appendChild(print_script);

                print_iframe.contentWindow.document.body.innerHTML = html;
                print_iframe.contentWindow.document.body.appendChild(print_script_tag);
                print_iframe.contentWindow.Print();
            };
                   
            document.body.appendChild(print_iframe);
            print_iframe.remove();
        },
        positiveOrNegative(value) {
            return value < 0 ? 'text-danger' : value == 0 ? 'text-black' : 'text-success';
        },
        calcCommission(total_apuracao, total_premios, total_comissao, total_comissao_ao_vivo, comissao, comissao_do_apurado) {

           let valor = 0;
           let subtrai = total_apuracao - total_premios - total_comissao - total_comissao_ao_vivo;

           if (comissao_do_apurado == 'sim') valor = (total_apuracao / 100) * comissao;
           if (subtrai > 0 && comissao_do_apurado == 'nao') valor = (subtrai / 100) * comissao;

           return valor;
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        },
    },
    filters: {
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        }
    },
    beforeMount() {
        try {
            
            const {pipe} = JSON.parse(window.localStorage.getItem('auth'));

            this.pipe = pipe;
        } catch(e) {
            this.pipe = 0;
        }
    },
    created() {

        const self = this;

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
</style>